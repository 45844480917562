.carousel-wrap {
  box-sizing: border-box;
  margin: 12px auto;
  padding: 0;
  position: relative;
  width: 100%;
}
/*Border color item*/
.borderCircle {
  border-radius: 50%;
  height: 84px;
  margin-left: -3px;
  margin-top: -3px;
  position: absolute;
  width: 84px;
}

.lightBlueMovistar {
  border: 1px solid #019df4;
  box-shadow: 0 5px 11px rgb(0 0 0 / 21%);
}

.greenMovistar {
  border: 1px solid #5CB615;
  box-shadow: 0 5px 11px rgb(0 0 0 / 21%);
}

.yellow {
  border: 1px solid #019df4;
  box-shadow: 0 5px 11px rgb(0 0 0 / 21%);
}
.blue1 {
  border: 1px solid #197eb4;
  box-shadow: 0 5px 11px rgb(0 0 0 / 21%);
}
.blue2 {
  border: 1px solid #12638f;
  box-shadow: 0 5px 11px rgb(0 0 0 / 21%);
}
.blue3 {
  border: 1px solid #90c0da;
  box-shadow: 0 5px 11px rgb(0 0 0 / 21%);
}
.black {
  border: 1px solid #0b2739;
  box-shadow: 0 5px 11px rgb(0 0 0 / 21%);
}

/* fix blank or flashing items on carousel */
.owl-carousel .item {
  background-color: transparent !important;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  z-index: 100;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.item {
  height: 110px;
  padding-top: 5px !important;
  width: 80px;
}
.item a {
  color: #7f7f7f;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
.labelMen {
  padding: 5px 0;
  margin-top: 5px;
}

/* end fix */
.owl-nav > div {
  margin-top: -26px;
  position: absolute;
  top: 50%;
  color: #cdcbcd;
}

.owl-nav i {
  font-size: 52px;
}

.owl-nav .owl-prev {
  left: -30px;
}

.owl-nav .owl-next {
  right: -30px;
}

/**
 * Owl Carousel v2.1.1
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: pan-Y;
  margin: 0 auto;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  /*border-right: 1px solid #cdcdcd;border-left: 1px solid #cdcdcd;*/
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  border-radius: 50%;
}
.owl-carousel .owl-item:hover img {
  background-color: red;
}
.owl-carousel,
.owl-carousel .owl-nav.disabled {
  display: none;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  display: none;
}
.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.no-js .owl-carousel {
  display: block;
}
.owl-carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(../images/owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.owl-dots {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  list-style: none;
  text-align: center;
}
.owl-dots button {
  position: relative;
  font-size: 0;
  line-height: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 5px;
  border: 0;
  cursor: pointer;
  color: transparent;
  outline: none;
  background: transparent;
}
.owl-dots button:hover,
.owl-dots button:focus {
  outline: none;
}
.owl-dots button:hover:before,
.owl-dots button:focus:before {
  opacity: 0.25;
}
/* TODO: Tipografia  */
.owl-dots button:before {
  font-family: "museo_sans100";
  font-size: 56px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: rgba(1, 157, 244, 0.9);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.owl-dots button.owl-dot.active:before {
  opacity: 0.75;
  color: #016af4;
}