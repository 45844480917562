@charset 'UTF-8';

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  bottom: 0;
  top: 0;

  display: block;

  width: 50px;
  height: auto;
  padding: 0;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.8);

  z-index: 1 !important;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "museo_sans100" !important;
  font-size: 60px !important;

  opacity: 0.75;
  color: #019df4;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 0px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: "‹" !important;
}
[dir="rtl"] .slick-prev:before {
  content: "›" !important;
}

.slick-next {
  right: 0px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: "›" !important;
}
[dir="rtl"] .slick-next:before {
  content: "‹" !important;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  margin-bottom: 20px;
  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 0.25;
}
.slick-dots li button:before {
  font-family: "museo_sans100";
  font-size: 56px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: rgba(1, 157, 244, 0.9);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #016af4;
}
@media only screen and (min-width: 1490px) {
  .slick-prev,
  .slick-next {
    background: rgba(255, 255, 255, 0);
  }
}
@media only screen and (max-width: 1489px) {
  .slick-prev,
  .slick-next {
    background: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (max-width: 600px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}
