.faq {
  p {
    text-align: justify;
  }

  .bold {
    font-weight: bold;
  }


  .clickable {
    cursor: pointer;
    color: cornflowerblue;
  }
}
