
.ToneConfigurationModal {

  .confirm-all-calls-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
  }

  label {
    cursor: pointer;
  }
  .ToneCallers {

    padding: 1rem 0;

    .input-and-button-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .input-wrapper {
      width: 70%;
      flex: 0 1 auto;
    }

    .button-wrapper {
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-end;
    }

    .callers-wrapper {

      margin-top: 1rem;

      .caller:nth-of-type(odd) {
        background-color: #00bdbc;
      }

      .caller {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        color: black;
        border-radius: 5px;

        .icon {
          color: #bd000b;
          cursor: pointer;
          font-size: 28px;

          &:hover {
            color: #880f00;
          }
        }
      }
    }
  }
}
