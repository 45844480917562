@font-face {
  font-family: "museo_sans500";
  src: url("../images/museosans_500-webfont.woff2") format("woff2"),
    url("../images/museosans_500-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "telefonica_extraLight";
  src: url("../images/Telefonica-ExtraLight.otf"),
    url("../images/Telefonica-ExtraLight.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "telefonicaCap_regular";
  src: url("../images/TelefonicaCap-Regular.otf"),
    url("../images/TelefonicaCap-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "telefonica_bold";
  src: url("../images/Telefonica-Bold.otf"),
    url("../images/Telefonica-Bold.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "telefonica_regularItalic";
  src: url("../images/Telefonica-RegularItalic.otf"),
    url("../images/Telefonica-RegularItalic.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "telefonica_light";
  src: url("../images/Telefonica-Light.otf"),
    url("../images/Telefonica-Light.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "telefonica_regular";
  src: url("../images/Telefonica-Regular.otf"),
    url("../images/Telefonica-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "museo_sans100";
  src: url("../images/museosans-100-webfont.woff2") format("woff2"),
    url("../images/museosans-100-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/*General*/
html {
  height: 100%;
}
body {
  background-color: #ffffff;
  background-image: url(../images/background.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: #a0a0a0;
  font-family: "telefonica_regular" !important;
  font-size: 13px;
  margin: 0;
  padding: 0;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
h1,
h2,
h3 {
  color: #7f7f7f;
}
a {
  text-decoration: none;
}
img {
  width: 100%;
  border-radius: 10px;
}

/* remove-horizontal-scroll-bar-on-mobile */
::-webkit-scrollbar-x {
  display: none;
}
/**********.Classes*********/
.row {
  margin-right: 0;
  margin-left: 0;
  margin-top: 10px
}
.row:before,
.row:after {
  display: table;
  content: " ";
}
.row:after {
  clear: both;
}
.row:before,
.row:after {
  display: table;
  content: " ";
}
.row:after {
  clear: both;
}

.content {
  flex: 1;
}

/************Promo Banner*********/
/* .PromoBan {
} */

/***********Navigation Bar**************/
nav ul,
nav ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
nav {
  background-color: rgba(255, 255, 255, 0.9);
  height: 70px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}
.navCont {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1440px;
  position: relative;
  width: 100%;
}
.logo {
  background-image: url(../images/logoBlue.svg);
  background-size: 300px 43px;
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  height: 43px;
  margin: 10px;
  width: 300px;
}

@media only screen and (max-width: 600px) {
  nav {
    height: 60px;
  }
  .logo {
    background-size: 220px 32px;
    height: 32px;
    margin: 14px;
    width: 220px;
  }
}
/**********Expandable Menu**********/
.menu {
  background-color: rgba(1, 157, 244, 0.9);
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 60%);
  height: auto;
  left: 0;
  padding: 25px 20px;
  right: 0;
  top: 70px;
  width: 100%;
  position: fixed;
}
/*.menu.open {
  display:block;
  transition:all 0.5s ease-in-out;
}*/
.menuCont {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.listCont {
  float: right;
  margin: 0 20px;
  max-width: 300px;
  text-align: right;
  width: 100%;
}
.menuCont ul {
  padding: 0;
}
.menuCont ul li {
  list-style: none;
  padding: 15px 3px;
}
.menuCont ul li a {
  color: #ffffff;
  font-size: 13px;
  text-decoration: none;
}
.menuCont ul li a:hover {
  color: #019df4;
}

.search-arrow-wrapper {
  float: right;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-input-container {
  position: relative;
}

.search-input-label {
  position: absolute;
  top: 50%;
  left: 5px;
  height: 10px;
  margin-top: -11px;
}

.search-icon {
  width: 20px;
  border-radius: 0;
  cursor: pointer;
  transition: 0.5s;
}

.search-icon:hover {
  transition: 0.5s;
  color: gray;
}

.search-arrow-wrapper input {
  padding: 8px 8px 8px 30px;
  -webkit-appearance: none;
  margin-right: 20px;
  border: 1px solid gray;
  border-radius: 5px;
  transition: 0.5s;
  background: transparent;
}
::-moz-focus-inner {
  border: 0;
}
.search-arrow-wrapper input[value=""] {
  padding: 8px 8px 8px 30px;
  -webkit-appearance: none;
  margin-right: 20px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  transition: 0.5s;
  background: transparent;
}

.arrow {
  width: 135px;
  height: 40px;
  background: #019df4;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  margin: 15px 20px 15px 40px;
  z-index: 2;
  margin-right: 60px;
}
.arrow:focus {
  outline: none;
}
.arrow:active {
  background: #019df4;
}
.arrow-hand {
  background-color: #7f7f7f;
  width: 15px;
  height: 1px;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: 0;
  transition: all 0.4s ease-in-out;
 
}
.arrowCont {
  height: 30px;
  margin-left: 140px;
  margin-top: -5px;
  position: absolute;
  width: 50px;
  
}
.arrow-hand.top {
  transform: rotate(45deg);
  left: 5px;
}
.arrow-hand.bottom {
  transform: rotate(-45deg);
  left: 15px;
}

.arrow.cross .arrow-hand {
  transition: all 0.4s ease-in-out;
}
.arrow.cross .arrow-hand.top {
  transform: rotate(-45deg);
}
.arrow.cross .arrow-hand.bottom {
  transform: rotate(45deg);
}
.SubsBut {
  background-image: url(../images/login.png);
  background-size: 22px 23px;
  background-position: left center;
  background-repeat: no-repeat;
  height: 22px;
  margin-left: 5px;
  margin-top: -5px;
  width: 130px;
}

.SubsBut p {
  color: #ffffff;
  display: block;
  font-size: 12px;
  margin: 0;
  padding: 5px;
}
@media only screen and (max-width: 700px) {
  .menu {
    padding: 15px 20px;
    top: 50px;
  }
  .search-arrow-wrapper {
		display: flex;
		flex-direction: column-reverse;
		align-items: flex-end;
		position: absolute;
		right: 0;
		width: 0;
	}
	.arrow {
		width: 40px;
		height: 30px;
		margin: 15px 40px 5px 20px;
	}
  .arrowCont {
    height: 25px;
    margin-left: 30px;
    width: 35px;
  }
  .arrow-hand {
    width: 10px;
  }
  .arrow-hand.top {
    left: 8px;
  }
  .SubsBut {
    background-size: 18px 19px;
    height: 19px;
    margin-top: -4px;
    width: 18px;
  }
  .SubsBut p {
    display: none;
  }
}
/********Artist and Genres***********/
/* Style the tab */
.TabCont {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 15px;
  position: relative;
  width: 100%;
}
.tab {
  overflow: hidden;
  border-bottom: 2px solid #019df4;
  margin: 70px auto 20px auto;
  margin-top: 70px;
  padding: 10px 0;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: #fff;
  color: #c7c7c7;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 16px;
  transition: 0.3s;
  font-family: "telefonica_regular";
  font-size: 17px;
  letter-spacing: 0px;
}
.tab button:first-child {
  background-color: #fff;
  color: #c7c7c7;
  float: left;
  border-right: 1px solid #c7c7c7;
  outline: none;
  cursor: pointer;
  padding: 0 16px 0 0;
  transition: 0.3s;
  font-family: "telefonica_regular";
  font-size: 17px;
  letter-spacing: 0px;
}
/* TODO: Tipografia */
.tab span {
  font-family: "telefonica_regular";
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  color: #7f7f7f;
}

/* Create an active/current tablink class */
.tab button.active {
  color: #7f7f7f;
}

/* Style the tab content */
.tabcontent {
  display: none;
  border-top: none;
  max-width: auto;
}
.tabcontent {
  animation: fadeEffect 2s; /* Fading effect takes 1 second */
}

/* Go from zero to full opacity */
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/**********Slider Banner*******/

.mySlides {
  display: none;
}

/* Slideshow container */
.slideshow-container {
  box-sizing: border-box;
  position: relative;
  margin: auto;
  max-width: 1440px;
  width: 100%;
}
/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -45px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  z-index: 1;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
/*.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}*/
.triangle-left {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-right: 25px solid #019df4;
  border-bottom: 20px solid transparent;
  transition: .5s;
}
.triangle-right {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 25px solid #019df4;
  border-bottom: 20px solid transparent;
  transition: .5s;
}
.triangle-right:hover {
  border-left: 25px solid #00a4af;
}
.triangle-left:hover {
  border-right: 25px solid #00a4af;
}

/* Caption text */
.text {
  box-sizing: border-box;
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}

/***********Carousel Section************/
.titleCont {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 15px;
  position: relative;
  width: 100%;
}
.titleCont2 {
  box-sizing: border-box;
  margin: 30px auto 0;
  max-width: 1440px;
  padding: 0 15px;
  position: relative;
  width: 100%;
}
.titlePpal b {
  font-family: "telefonica_regular";
  font-size: 17px;
}
.titlePpal {
  border-bottom: 2px solid #019df4;
  box-sizing: border-box;
  font-family: "telefonica_regular";
  font-size: 17px;
  letter-spacing: 0px;
  margin: 0 auto 20px auto;
  max-width: 1440px;
  padding: 10px 0;
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-50%, -25%);
  -ms-transform: translate(-50%, -25%);
  text-align: center;
}

.container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.play {
  background-color: rgba(1, 157, 244, 0.5);
  background-image: url(../images/play.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
  border-radius: 50%;
  bottom: 5px;
  height: 60px;
  right: 5px;
  width: 60px;
}
.TextDesc1 {
  color: #7f7f7f;
  font-size: 13px;
  margin: 0;
  padding: 7px 10px;
}
.TextDesc2 {
  color: #7f7f7f;
  font-size: 11px;
  line-height: 0.1;
  margin: 0;
  padding: 5px 10px;
}
@media only screen and (max-width: 600px) {
  .TextDesc1 {
    font-size: 11px;
  }
}
/**********Footer************/
footer {
  background-color: rgba(245, 245, 247, 1);
  border-top: 1px solid #d2d2d7;
  box-sizing: border-box;
  font-size: 12px;
  height: auto;
  margin-top: 35px;
  padding: 20px;
  position: relative;
  width: 100%;

  grid-row-start: 2;
  grid-row-end: 3;
}
.disclaimer {
  font-size: 12px;
  float: left;
  padding: 10px 0;
  position: relative;
  text-align: center;
  width: 100%;
}
.footCont {
  margin: 0 auto;
  max-width: 1440px;
  text-align: center;
  width: 100%;
}
.footCont ul {
  display: inline-flex;
  margin: 0;
  padding: 10px 0;
}
.footCont ul li:first-child {
  border-left: none;
  list-style: none;
  padding: 0 15px;
}
.footCont ul li {
  border-left: 1px solid #666666;
  list-style: none;
  padding: 0 15px;
}
.footCont ul li a {
  display: block;
  text-decoration: none;
  color: #7f7f7f;
  font-size: 12px;
}
.footCont ul li a:hover {
  color: #2b2b2b;
}
@media only screen and (max-width: 600px) {
  footer,
  .disclaimer {
    font-size: 11px;
  }
}
/**********Modal***********/

.btn {
  background: #019df4;
  border: #019df4 solid 0px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: 8px 15px;
  text-decoration: none;
  text-align: center;
  margin: 0 5px;
  min-width: 60px;
  position: relative;
  transition: 0.5s ease;
}
 /*Agregado*/
.btn:disabled {                        
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

 /*Agregado*/
.btn:not([disabled]):hover {
  background: #007288;
}
.btn:hover {
  background: #019df4;
}

.btn2 {
  background: #6d6d6d;
  border: #6d6d6d solid 0px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: 8px 15px;
  text-decoration: none;
  text-align: center;
  margin: 10px 0;
  min-width: 60px;
  position: relative;
  transition: 0.5s ease;
}
.btn2:hover {
  background: #373737;
}
.btn.btn-big {
  font-size: 18px;
  padding: 15px 20px;
  min-width: 100px;
}
.btn-close {
  color: #aaaaaa;
  font-size: 20px;
  text-decoration: none;
  padding: 10px;
  position: absolute;
  right: 7px;
  top: 0;
}
.btn-close:hover {
  color: #919191;
}
/* .popLinks {
} */
.popLinks a {
  color: #a0a0a0;
  font-size: 11px;
  padding: 3px 5px 3px 5px;
  text-decoration: none;
}
.popLinks a:hover {
  color: #7f7f7f;
}
.show {
  display: block;
}
.modale:before {
  content: "";
  display: none;
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.opened:before {
  display: block;
}
.opened .modal-dialog {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: block;
  border-radius: 15px;
}
.modal-dialog {
  background: #fefefe;
  border: #333333 solid 0px;
  border-radius: 5px;
  display: none;
  text-align: center;
  max-width: 500px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 11;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.modal-body {
  box-sizing: border-box;
  padding: 20px;
}
.modal-body input {
  width: 200px;
  padding: 8px;
  border: 1px solid #ddd;
  color: #888;
  outline: 0;
  font-size: 14px;
  font-weight: bold;
}
.modal-body input[type="radio"] {
  width: 20px;
  padding: 8px;
  border: 1px solid #ddd;
  color: #888;
  outline: 0;
  margin: 10px;
  font-size: 14px;
  font-weight: bold;
}
.column1 {
  width: 0%;
  float: left;
}
.column2 {
  width: 100%;
  max-width: 440px;
  float: left;
  font-size: 0.875em;
  margin: 10px 15px;
  padding: 0 20px;
  position: absolute;
  box-sizing: border-box;
}
.column2 input[type="radio"] {
  float: left;
}

/*Agregado*/
.column2:hover {
  color: grey;
}

.modal-footer {
  display: inline-block;
  margin: 10px 0 5px 0;
}
.modal-header {
  padding: 10px 45px 10px 20px;
}
.modal-footer {
  padding: 10px 20px 30px 20px;
}
.modal-header {
  border-bottom: #eeeeee solid 1px;
}
.modal-header h2 {
  font-size: 20px;
  text-align: left;
}

.modal-footer .terms-and-conditions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-footer .terms-and-conditions .navigation {
  color: #00bdbc;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .modale:before {
    background: rgba(255, 255, 255, 1);
  }
  .modal-dialog {
    background: transparent;
    box-shadow: none;
  }
}
/*Inputs*/

:focus {
  outline: none;
}

/* Important to position input*/
.inputCont {
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin: 13px 0;
  position: relative;
  text-align: left;
  z-index: 5;
}
input[type="text"],
input[type="password"],
input[type="date"] {
  font: 15px/24px "telefonica_regular", Arial, sans-serif;
  color: #7f7f7f;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 0px;
  background: none;
  border-radius: 5px;
}

.effect-20 {
  border: 1px solid #ccc;
  padding: 7px 14px;
  transition: 0.4s;
  background: transparent;
  border-radius: 10px;
}
.effect-20 ~ .focus-border:before,
.effect-20 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #019df4;
  transition: 0.3s;
  border-radius: 10px;
}
.effect-20 ~ .focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
.effect-20 ~ .focus-border i:before,
.effect-20 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #019df4;
  transition: 0.4s;
  border-radius: 10px;
}
.effect-20 ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}
.effect-20:focus ~ .focus-border:before,
.effect-20:focus ~ .focus-border:after,
.has-content.effect-20 ~ .focus-border:before,
.has-content.effect-20 ~ .focus-border:after {
  width: 100%;
  transition: 0.3s;
}
.effect-20:focus ~ .focus-border i:before,
.effect-20:focus ~ .focus-border i:after,
.has-content.effect-20 ~ .focus-border i:before,
.has-content.effect-20 ~ .focus-border i:after {
  height: 100%;
  transition: 0.4s;
}

.effect-20 ~ label {
  position: absolute;
  left: 14px;
  width: 100%;
  top: 13px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0px;
  text-align: left;
}
.effect-20:focus ~ label,
.has-content.effect-20 ~ label {
  top: -18px;
  left: 15px;
  font-size: 11px;
  color: #019df4;
  transition: 0.3s;
}

/*End input*/

/*Floating Banner*/
.PromoBan {
  background-color: rgba(1, 157, 244, 0.8);
  bottom: 0;
  height: auto;
  left: 0;
  padding: 20px;
  position: fixed;
  right: 0;
  z-index: 10;
}
.FloatBanLeft {
  box-sizing: border-box;
  float: left;
  text-align: left;
  width: 60%;
}
.FloatBanRight {
  box-sizing: border-box;
  float: right;
  padding-right: 20px;
  text-align: right;
  width: 40%;
}
.FloatBanClose {
  float: right;
  text-align: right;
  width: 10%;
}
.FloatH2 {
  color: #ffffff;
  font-size: 20px;
  margin: 0;
  text-align: left;
}
.FloatText {
  color: #ffffff;
  font-size: 10.7px;
  margin: 0 2px;
  text-align: left;
}
.btn-closeBan {
  color: #ffffff;
  font-size: 20px;
  text-decoration: none;
  padding: 25px 7px;
  position: absolute;
  right: 7px;
  top: 0;
}
.btn-closeBan:hover {
  color: #019df4;
}
@media only screen and (max-width: 500px) {
  .FloatBanLeft {
    box-sizing: border-box;
    float: left;
    text-align: left;
    width: 55%;
  }
  .FloatBanRight {
    box-sizing: border-box;
    float: right;
    padding-right: 20px;
    text-align: right;
    width: 45%;
  }
  .FloatH2 {
    color: #ffffff;
    font-size: 13px;
    margin: 0;
    text-align: left;
  }
  .FloatText {
    color: #ffffff;
    font-size: 9px;
    margin: 0 2px;
    text-align: left;
  }
}
/*Internas*/
.ArtistCont {
  background: rgb(0, 114, 136);
  background: linear-gradient(
    180deg,
    rgba(1, 157, 244, 1) 0%,
    rgba(1, 157, 244, 1) 100%
  );
  box-shadow: inset 0 8px 10px rgb(0 0 0 / 21%);
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 30px 20px 80px 20px;
  position: relative;
  width: 100%;
}
.Artist1 {
  position: relative;
  width: 100%;
  height: auto;
}
.InternLabel {
  background-color: #0b2739;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  position: absolute;
  text-align: center;
}
.InternLabel h1 {
  color: #ffffff;
  font-size: 20px;
  margin: 0;
}
.ArtistCircle {
  border: 8px solid #a1cce4;
  border-radius: 50%;
  height: 250px;
  margin-left: -3px;
  margin-top: -3px;
  position: relative;
  width: 250px;
  padding: 0px;
  box-shadow: 0 8px 10px rgb(0 0 0 / 21%);
  box-sizing: border-box;
  margin: 0 auto;
}
.ArtistCircle img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  border-radius: 50%;
  border: 6px solid #fff;
  box-sizing: border-box;
}
.GenCont {
  box-sizing: border-box;
  margin: 0 auto -20px auto;
  max-width: 1440px;
  padding: 0;
  position: relative;
  width: 100%;
}
.SongCont {
  background: rgb(0, 114, 136);
  background: linear-gradient(
    180deg,
    rgba(1, 157, 244, 1) 0%,
    rgba(1, 157, 244, 1) 100%
  );
  box-shadow: inset 0 8px 10px rgb(0 0 0 / 21%);
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 30px 20px 30px 20px;
  position: relative;
  width: 100%;
}
.CoverCont {
  display: table;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
}
.CoverSong {
  box-sizing: border-box;
  float: left;
  max-width: 215px;
  width: 35%;
}
.CoverSong img {
  border-radius: 8px;
  box-shadow: 0 8px 10px rgb(0 0 0 / 21%);
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  box-sizing: border-box;
}
.SongDecription {
  box-sizing: border-box;
  float: left;
  padding: 0 0 0 30px;
  width: 65%;
}
.SongDecription h1 {
  color: #ffffff;
  font-size: 20px;
  margin: 15px 0;
}
.SongDecription p {
  color: #ffffff;
  font-size: 13px;
  margin: 5px 0;
}
.btnCont {
  max-width: 150px;
  padding: 5px 0;
}
.btnListen {
  background: #019df4;
  background-image: url(../images/PlayIcon.png);
  background-size: 28px 23px;
  background-position: left;
  background-repeat: no-repeat;
  border: #057bbb solid 0px;
  border-radius: 5px;
  box-shadow: 0 3px 7px rgb(0 0 0 / 21%);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: 8px 15px 8px 35px;
  text-decoration: none;
  text-align: center;
  margin: 5px 0;
  min-width: 60px;
  position: relative;
  transition: 0.5s ease;
}
/*Agregado*/
.btnPauseListen {
  background: #007288;
  background-image: url(../images/pauseIconWhite.png);
  background-size: 28px 23px;
  background-position-y: center;
  background-repeat: no-repeat;
  border: #007288 solid 0px;
  border-radius: 5px;
  box-shadow: 0 3px 7px rgba(0 0 0, 0.21);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: 12px 15px 12px 35px;
  text-decoration: none;
  text-align: center;
  margin: 5px 0;
  min-width: 60px;
  position: relative;
  transition: 0.5s ease;
}
/*Agregado*/
.btnPauseListen:hover {
  background: #00a4af;
  background-image: url(../images/pauseIconWhite.png);
  background-size: 28px 23px;
  background-position-y: center;
  background-repeat: no-repeat;
}

.btnListen:hover {
  background: #6fbbe4;
  background-image: url(../images/PlayIcon.png);
  background-size: 28px 23px;
  background-position: left;
  background-repeat: no-repeat;
}
.btnBuy {
  background: #0b2739;
  background-image: url(../images/ShopCart.png);
  background-size: 28px 23px;
  background-position: left;
  background-repeat: no-repeat;
  border: #0b2739 solid 0px;
  border-radius: 5px;
  box-shadow: 0 3px 7px rgb(0 0 0 / 21%);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: 8px 15px 8px 35px;
  text-decoration: none;
  text-align: center;
  margin: 5px 0;
  min-width: 60px;
  position: relative;
  transition: 0.5s ease;
}
.btnBuy:hover {
  background: #373737;
  background-image: url(../images/ShopCart.png);
  background-size: 28px 23px;
  background-position: left;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 600px) {
  .InternLabel {
    padding: 10px 20px;
  }
  .btnCont {
    max-width: 500px;
    padding: 5px 0;
  }
  .btnListen,
  .btnBuy {
    margin: 15px 5px;
  }
}
@media only screen and (max-width: 500px) {
  .CoverSong {
    float: none;
    margin: 0 auto;
    width: 100%;
  }
  .SongDecription {
    box-sizing: border-box;
    float: left;
    padding: 0;
    text-align: center;
    width: 100%;
  }
  .btnCont {
    max-width: 500px;
    padding: 5px 0;
  }
  .btnListen,
  .btnBuy {
    margin: 15px 5px;
  }
}
/*Mi Cuenta*/
.GrayBar {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0px 7px rgb(0 0 0 / 27%);
  display: grid;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.AccountLimit {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1440px;
  padding: 20px;
  width: 100%;
}
.AccountCont {
  box-sizing: border-box;
  float: left;
  padding: 5px;
  width: 33.333%;
}
.AccountBase {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 3px 7px rgb(0 0 0 / 21%);
  box-sizing: border-box;
  display: inline-block;
  padding: 20px 20px 50px 20px;
  position: relative;
  width: 100%;
}

.AccountBase img {
  border: 1px solid #019df4;
  border-radius: 50%;
  max-width: 100px;
  padding: 2px;
  width: 100%;
}
.AccountBase h1 {
  font-size: 13px;
  margin: 0;
  text-align: left;
}
.AccountBase p {
  font-size: 9px;
  margin: 5px 0 0 0;
  text-align: left;
}
.AccountCol1 h1 {
  font-size: 13px;
  margin: 0;
  text-align: center;
}
.AccountCol1 p {
  font-size: 9px;
  margin: 5px 0 0 0;
  text-align: center;
}

.AccountCol1 {
  float: left;
  padding-top: 10px;
  width: 15%;
}
.AccountCol2 {
  box-sizing: border-box;
  float: left;
  padding-left: 15px;
  width: 85%;
}
.AccountRow1 {
  float: left;
  width: 100%;
}
.AccountRow2 {
  float: left;
  width: 50%;
}
.settingBar {
  background-color: transparent;
  border-top: 1px solid #eeeeee;
  width: 100%;
  padding: 5px 10px;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 10px 10px;
  text-align: right;
}
.btnPause {
  background: transparent;
  background-image: url(../images/pauseIcon.png);
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 28px;
  margin: 0 5px;
  text-decoration: none;
  position: relative;
  transition: 0.5s ease;
  width: 28px;
}
.btnPlay {
  background: transparent;
  background-image: url(../images/soundIcon1.png);
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 28px;
  margin: 0 5px;
  text-decoration: none;
  position: relative;
  transition: 0.5s ease;
  width: 28px;
}
.btnPlay:hover {
  background-image: url(../images/soundIcon.png);
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}
.btnSetting {
  background: transparent;
  background-image: url(../images/settingIcon1.png);
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 28px;
  margin: 0 5px;
  text-decoration: none;
  position: relative;
  transition: 0.5s ease;
  width: 28px;
}
.btnSetting:hover {
  background-image: url(../images/settingIcon.png);
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}
.btnTrash {
  background: transparent;
  background-image: url(../images/trashIcon1.png);
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 28px;
  margin: 0 5px;
  text-decoration: none;
  position: relative;
  transition: 0.5s ease;
  width: 28px;
}
.btnTrash:hover {
  background-image: url(../images/trashIcon.png);
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1000px) {
  .AccountCont {
    width: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .AccountCont {
    width: 100%;
  }
}
.ParaphCont {
  margin-top: 80px;
  width: 100%;
}
.Paraph {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 25px;
  text-align: left;
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

.loader {
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #00bdbc;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load 0.8s infinite linear;
  animation: load 0.8s infinite linear;
}

.loader-white {
  border-left: 1.1em solid white;
}

.profile-loader {
  height: 3em;
  width: 3em;
  border-left: 1.1em solid lightgrey;
}
@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}