/*General Styles*/
/*Circle navigation menu*/

.CircleCont {
  float: left;
  padding: 3px;
  position: relative;
  width: 12.5%;
}
.Circle {
  background-color: black;
  border-radius: 50%;
  height: 85px;
  width: 85px;
}

.slick-prev {
  z-index: 1 !important;
  left: 0 !important;
}

.slick-prev:before {
  content: "‹" !important;
  font-size: 60px !important;
}

.slick-next {
  right: 0 !important;
}

.slick-next:before {
  content: "›" !important;
  font-size: 60px !important;
}

.item {
  transition: 0.5s;
}

.item:hover {
  transition: 0.5s;
  background-color: black;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 100%);
  overflow: hidden;
  scroll-behavior: smooth;
}
.wrapper section {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(7, auto);
  margin: 20px 0;
}

.wrapper section .item {
  padding: 0 2px;
  transition: 250ms all;
}
.wrapper section .item:hover {
  margin: 0;
}
.wrapper section a {
  position: absolute;
  color: #fff;
  text-decoration: none;
  font-size: 6em;
  background: #000;
  width: 80px;
  padding: 20px;
  text-align: center;
  z-index: 1;
}
.wrapper section a:nth-of-type(1) {
  top: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    -90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}
.wrapper section a:nth-of-type(2) {
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}
